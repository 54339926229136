<template>
  <div class="member-manage">
    <div class="search-header mb-16">
      <a-form layout="inline"
              :model="searchForm"
              :colon="false">
        <a-form-item label="姓名">
          <a-input v-model:value="searchForm.staffName"
                   placeholder="请输入要搜索的姓名"
                   v-debounce="onSearchSubmit" />
        </a-form-item>
        <a-form-item label="角色">
          <a-select v-model:value="searchForm.roleCode"
                    style="width: 180px"
                    placeholder="请选择角色"
                    :options="roleList"
                    allowClear
                    @change="onSearchSubmit" />
        </a-form-item>
        <a-form-item label="席位状态">
          <a-select v-model:value="searchForm.staffStatus"
                    style="width: 180px"
                    placeholder="请选择席位"
                    :options="statusList"
                    allowClear
                    @change="onSearchSubmit" />
        </a-form-item>
      </a-form>
    </div>

    <div v-if="user.role === 'ROLE_ADMIN'"
         class="flex justify-between">
      <div class="sync-button my-0">
        <a-button type="link"
                  :disabled="isSync"
                  @click="handleSyncButton">
          <SyncOutlined :spin="isSync" />同步
        </a-button>
        <span class="content">{{ syncTime === 0 ? '' : `最后同步时间:${syncTime}` }}</span>
      </div>
      <div class="flex">
        <div class="text-color-999">
          已购席位数:<span class="text-warning"> {{totalNum}} </span>个，还可以为<span class="text-danger"> {{laveNum}}
          </span>个员工开启席位
        </div>
        <div v-show="endDateVisible"
             class="text-color-999">席位到期: <span class="text-danger">{{ $f.datetime(endDate, 'YYYY-MM-DD') }}</span></div>
      </div>
    </div>

    <div class="flex mt-8">
      <div class="table-sider"
           style="height: calc(100vh - 230px)">
        <h3 class="mt-16 mb-8 ml-16">部门信息</h3>
        <a-menu mode="inline">
          <a-tree v-model:selectedKeys="selectedKeys"
                  :defaultExpandAll="true"
                  :show-icon="true"
                  :tree-data="treeData"
                  :replaceFields="replaceDepartDataKey"
                  @select="departGroupClick">
            <template #book>
              <FolderFilled />
            </template>
            <template #title="{ workWechatDeptId }">
              <div v-is="'ww-open-data'"
                   class="ww-open-data"
                   type="departmentName"
                   :openid="workWechatDeptId"></div>
            </template>
          </a-tree>
        </a-menu>
      </div>

      <a-table :columns="columns"
               :data-source="staffList"
               row-key="id"
               :scroll="{ x: '100%', y: 'calc(100vh - 350px)' }"
               :pagination="pagination"
               :loading="tableLoading"
               @change="onPaginationChange">
        <template #workWechatThirdAppUserId="{ record }">
          <avatar-column :avatar="{avatar: record.avatar, userId: record.workWechatThirdAppUserId}"
                         round
                         is-user-id />
        </template>

        <template #workWechatDeptId="{ text }">
          <div v-is="'ww-open-data'"
               class="ww-open-data"
               type="departmentName"
               :openid="text"></div>
        </template>

        <template #roleName="{ record }">
          <span v-if="record.roleName !== '部门管理员'">{{record.roleName}}</span>
          <div v-else>
            <p>{{record.roleName}}</p>
            <div class="text-12 text-color-999">
              <template v-for="(deptName, index) in record.depWorkWechatDeptIds"
                        :key="index">
                <div v-is="'ww-open-data'"
                     class="ww-open-data"
                     type="departmentName"
                     :openid="deptName"></div>
                <span v-if="index !==  record.depWorkWechatDeptIds.length - 1">, </span>
              </template>
            </div>

          </div>
        </template>

        <template #accountStatus="{ record }">
          <span>{{ record.accountStatus === 'open' ? "是" : "否" }}</span>
        </template>

        <template #action="{ record }">
          <a-space :size="16">
            <router-link :to="{ path: 'memberDetail',query:{ id: record.id}}">成员详情</router-link>

            <a-button v-if="user.role === 'ROLE_ADMIN'"
                      type="link"
                      @click="setRole(record)"
                      :disabled="record.id === user.id">设置角色</a-button>

            <div v-if="user.role === 'ROLE_ADMIN'">
              <a-popconfirm v-if="record.accountStatus ==='closed'"
                            title="确认开启该用户?"
                            :disabled="record.id === user.id"
                            @confirm="onDisableUser(record)">
                <a-button type="link"
                          :disabled="record.id === user.id">开启席位</a-button>
              </a-popconfirm>
              <a-popconfirm v-else-if="record.accountStatus === 'open'"
                            title="确认关闭该用户?"
                            :disabled="record.id === user.id"
                            @confirm="onEnableUser(record)">
                <a-button type="link"
                          :disabled="record.id === user.id">关闭席位</a-button>
              </a-popconfirm>
              <a-button type="link"
                        v-else
                        @click="warning">开启SCRM</a-button>
            </div>

          </a-space>
        </template>
      </a-table>

      <member-modal-role v-model:visible="roleVisible"
                         :staffDetail="staffDetail"
                         :treeData="treeData"
                         @changed="fetchPaginationData">
      </member-modal-role>
    </div>

  </div>
</template>

<script>
import _ from "lodash";
import { reactive, h } from "vue";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import { FolderFilled, SyncOutlined } from "@ant-design/icons-vue";
import { Tree, Modal, Space } from "ant-design-vue";

import AvatarColumn from "@/components/AvatarColumn";

import { apiStore } from "@/shared/service/api-client";
import router from "@/router";
import MemberModalRole from "./MemberModalRole.vue";
import staffApi from "@/service/api/staff";
import corporationApi from "@/service/api/corporation";
import { useRoute } from "vue-router";
import { getWxAgentConfig } from "@/global";

const liveCodeGroup = [];
export default {
  name: "MemberMange",
  components: {
    FolderFilled,
    SyncOutlined,
    ATree: Tree,
    ASpace: Space,
    AvatarColumn,
    MemberModalRole,
  },

  data() {
    const columns = [
      {
        title: "名称",
        dataIndex: "workWechatThirdAppUserId",
        key: "workWechatThirdAppUserId",
        width: 180,
        slots: { customRender: "workWechatThirdAppUserId" },
      },
      {
        title: "所属部门",
        key: "workWechatDeptId",
        dataIndex: "workWechatDeptId",
        width: 130,
        slots: { customRender: "workWechatDeptId" },
      },
      {
        title: "角色",
        dataIndex: "roleName",
        key: "roleName",
        width: 120,
        slots: { customRender: "roleName" },
      },
      {
        title: "开启席位",
        key: "accountStatus",
        dataIndex: "accountStatus",
        width: 130,
        slots: { customRender: "accountStatus" },
      },
      {
        title: "操作",
        key: "action",
        fixed: "right",
        width: apiStore.user.role === "ROLE_ADMIN" ? 240 : 120,
        slots: { customRender: "action" },
      },
    ];
    const replaceDepartDataKey = {
      children: "children",
      title: "title",
      key: "id",
    };
    const treeData = [];
    //这里可以修改数据的键
    return {
      columns,
      liveCodeGroup,
      selectedKeys: [],
      treeData,
      replaceDepartDataKey,

      staffDetail: {},
      roleVisible: false,
      totalNum: 0,
      laveNum: 0,
      //到期时间
      endDate: null,
      endDateVisible: false,
      //同步按钮
      syncTime: "",
      isSync: false,
      timer: null,
    };
  },
  computed: {
    user() {
      return apiStore.user;
    },
  },
  mounted() {
    this.getAllDepartments();
    this.getLicense();
    this.getStaffSyncTime();
  },
  setup() {
    const route = new useRoute();

    const searchForm = reactive({
      staffName: "",
      roleCode: undefined,
      staffStatus: undefined,
      departmentId: null,
    });

    _.assign(searchForm, route.query);

    const roleList = [
      {
        value: "ROLE_ADMIN",
        label: "管理员",
      },
      {
        value: "ROLE_STAFF",
        label: "普通成员",
      },
    ];

    const statusList = [
      {
        value: "open",
        label: "已开启",
      },
      {
        value: "closed",
        label: "未开启",
      },
    ];

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, staffApi.search);

    getWxAgentConfig();

    return {
      searchForm,
      roleList,
      statusList,

      staffList: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    };
  },

  beforeUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  },

  methods: {
    async getStaffSyncTime() {
      const lastSyncTimeObj = await staffApi.getLastSyncTime();
      // 修改返回 processStatus 如果不为 `finished`   lastSyncTime
      this.syncTime = lastSyncTimeObj.lastSyncTime;
      if (lastSyncTimeObj.processStatus !== "finished") {
        this.isSync = true;
        this.setInter();
      }
    },
    async getAllDepartments() {
      const res = await staffApi.getAllDepartments();
      this.treeData = this.iterateToAddIcons(res);
    },
    departGroupClick(key) {
      this.searchForm.departmentId = _.isArray(key) ? key[0] : key;
      this.fetchPaginationData();
    },
    async onDisableUser(user) {
      const isOpen = await staffApi.openStaff(user.id);
      if (isOpen.ok) {
        this.$message.success("已开启");
        this.fetchPaginationData();
        this.getLicense();
      } else {
        this.warning();
      }
    },
    async onEnableUser(user) {
      await staffApi.closeStaff(user.id);
      this.$message.success("已关闭");
      this.fetchPaginationData();
      this.getLicense();
    },
    warning() {
      Modal.warning({
        title: "提示",
        content: h(
          "span",
          {
            class: "text-danger",
          },
          `已购席位数${this.totalNum}个已用完，无法开启，请联系阔知客服`
        ),
      });
    },
    setRole(record) {
      this.staffDetail = record;
      this.roleVisible = true;
    },
    iterateToAddIcons(obj) {
      for (var name in obj) {
        if (typeof obj[name] === "object") {
          obj["slots"] = { icon: "book" };
          this.iterateToAddIcons(obj[name]);
        } else {
          obj["slots"] = { icon: "book" };
        }
      }
      return obj;
    },
    async getLicense() {
      const res1 = await corporationApi.get();
      this.totalNum = res1.accountNum;
      const endDate = this.showDueTime(res1.endDate);
      if (endDate <= 90) {
        this.endDate = res1.endDate;
        this.endDateVisible = true;
      } else {
        this.endDateVisible = false;
      }

      this.laveNum = await corporationApi.getSurplusAccountNum();
    },
    showDueTime(endDate) {
      const nowTime = Date.parse(new Date());
      const dueDate = (endDate - nowTime) / 86400000;
      return dueDate;
    },

    async handleSyncButton() {
      this.isSync = true;

      await staffApi.asyncAllStaffs();
      this.setInter();
    },
    setInter() {
      let i = 0;
      this.timer = setInterval(async () => {
        i++;
        if (i >= 10) {
          clearInterval(this.timer);
        }
        const lastSyncTimeObj = await staffApi.getLastSyncTime();
        if (lastSyncTimeObj.processStatus === "finished") {
          this.isSync = false;
          this.syncTime = lastSyncTimeObj.lastSyncTime;
          this.$message.success("同步成功");
          this.getAllDepartments();
          this.searchForm.departmentId = null;
          await this.fetchPaginationData();
          clearInterval(this.timer);
        }
      }, 10000);
    },
  },
};
</script>
<style lang="less" scoped>
.table-sider {
  overflow-x: auto;
}

.ant-table-wrapper {
  margin-left: @spacing-6x;
  flex: 1;
}

:deep(.anticon-caret-down.ant-tree-switcher-icon),
:deep(.ant-tree-iconEle.ant-tree-icon__customize) {
  color: @color-primary;
}
</style>